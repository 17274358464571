import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Helmet } from 'react-helmet'

const RequireAuth = ({ children }) => {
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);
  if (route !== 'authenticated') {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return (
    <>
      <Helmet>
      </Helmet>
      {children}
    </>
  )
}

export default RequireAuth
