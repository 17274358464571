import React, { useContext } from 'react'
import { AppPortal } from "svix-react";

import WebhookPortalContext from '../../services/webhookService.js';

const Webhooks = () => {
  const {webhookPortal} = useContext(WebhookPortalContext)
  
  return (
    <AppPortal url={
      webhookPortal
    } />
  )
}

export default Webhooks
