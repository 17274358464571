import React from 'react';
import { createRoot } from 'react-dom/client';
import { AmplifyProvider } from '@aws-amplify/ui-react';
import './index.css';
import '@aws-amplify/ui-react/styles.css';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import config from './aws-exports'
import App from './App';
import reportWebVitals from './reportWebVitals';


Sentry.init({
  dsn: config.sentry_dsn,
  integrations: [new BrowserTracing()],
  environment: config.stage,
  tracesSampleRate: 1.0,
});

import '@aws-amplify/ui-react/styles.css'; // default theme
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import "svix-react/style.css";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AmplifyProvider>
      <App />
    </AmplifyProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
