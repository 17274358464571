import { useState, useEffect, createContext } from "react"

// import exports from '../../src/aws-exports'
import { useAuthenticator } from '@aws-amplify/ui-react'

import {
  whoami as whoamiQuery,
} from '../../src/graphql/queries'
import { API, graphqlOperation } from 'aws-amplify'

const WhoAmIContext = createContext()
export default WhoAmIContext

const ContextState = () => {
  const { user } = useAuthenticator((context) => [context.user])
  
  const [whoAmI, setWhoAmI] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [businessName, setBusinessName] = useState('')
  const [name, setName] = useState({ firstName: '', lastName: '' })
  const [role, setRole] = useState('MEMBER')
  const [customerId, setCustomerId] = useState('')
  const [entitlements, setEntitlements] = useState([])
  const [webhookId, setWebhookId] = useState(null)
  
  const whoAmICall = async () => {
    if (user) {
      const result = await API.graphql({
        ...graphqlOperation(whoamiQuery),
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })
      console.log('whoamiResult', result)
      setWhoAmI(result.data.whoami)
    }
  }

  useEffect(() => {
    whoAmICall()
  }, [user])
  useEffect(() => {
    if (whoAmI) {
      console.log('parsing whoAmI', whoAmI)
      const { firstName, lastName, role, customerId, customer: { customerName, entitlements = [], customerWebhookId } = {} } = whoAmI
      setName({ firstName, lastName })
      setRole(role)
      setEntitlements(entitlements)
      setBusinessName(customerName)
      setCustomerId(customerId)
      setLoaded(true)
      setWebhookId(customerWebhookId)
    }
  }, [whoAmI])

  return {
    businessName,
    customerId,
    loaded,
    name,
    role,
    entitlements,
    webhookId,
  }
}

const WhoAmIContextProvider = (props) => {
  const state = ContextState()

  return (
    <WhoAmIContext.Provider value={state}>
      {props.children}
    </WhoAmIContext.Provider>
  ) 
}

export { WhoAmIContext, WhoAmIContextProvider }
