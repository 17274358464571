import { createGraphiQLFetcher } from '@graphiql/toolkit';
import { GraphiQL } from 'graphiql';
import { Typography, Box, CircularProgress } from '@mui/material'
import React from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import 'graphiql/graphiql.css';

import settings from '../../aws-exports'

const GraphqlPlayground = () => {

  const { user } = useAuthenticator((context) => [context.user])
  const authHeader = user?.signInUserSession?.accessToken?.jwtToken
  if (!authHeader) {
    return <CircularProgress />
  }
  const fetcher = createGraphiQLFetcher({ url: settings.aws_appsync_graphqlEndpoint, headers: { Authorization: authHeader } });
  return <GraphiQL fetcher={fetcher} />
}


const ApiPlayground = () => {
  return (
    <Box padding='1.5rem 2.5rem 0rem 2.5rem' sx={{ backgroundColor: 'background.main', minHeight: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Typography variant="h1" color="emphasis.dark">Mesh API Playground</Typography>
    
        <GraphqlPlayground />
    </Box>
  )
}

export default ApiPlayground