import { useEffect, useContext, useState, useMemo } from "react";
import queryString from 'query-string';
import { View, Image, Button, Loader, CheckboxField, TextField } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../../Images/meshLogo.svg'
import UserContext from "../../services/userService";
import './Signup.css'

const ERROR_MESSAGES = {
  InvalidPasswordException: 'Password is not correct. Password must be at least 8 characters in length and include numbers, uppercase and lowercase letters.',
}

const SignupForm = ({ businessName, signupUser, loading, globalError, globalErrorMessage, setGlobalError }) => {
  const [showWelcome, setShowWelcome] = useState(true)
  const [terms, setTerms] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [errors, setErrors] = useState({})

  const submitForm = () => {
    const newErrors = []
    if (!firstName) {
      newErrors.push(['firstName', 'Please complete both name fields'])
    }
    if (!lastName) {
      newErrors.push(['lastName', 'Please complete both name fields'])
    }
    if (!password) {
      newErrors.push(['password', 'Please enter a password'])
    }
    if (!confirmPassword) {
      newErrors.push(['confirmPassword', 'Please confirm the password'])
    }
    if (password && confirmPassword && password !== confirmPassword) {
      newErrors.push(['password', 'Passwords must match'])
      newErrors.push(['confirmPassword', 'Passwords must match'])
    }
    if (!terms) {
      newErrors.push(['terms', 'You must agree to our terms and conditions to continue'])
    }
    if (!newErrors.length > 0) {
      signupUser({ firstName, lastName, password })
    }
    setErrors(Object.fromEntries(newErrors))
  }

  if (globalError) {
    const errorMessage = globalErrorMessage || 'Oops! There was an error'
    return (
      <>
        <View className="center">
          <h2>{errorMessage}</h2>
        </View>
        <p>Please try again or contact <a href="emailto:support@mesh.id">support@mesh.id</a> if you continue to experience issues.</p>
        <Button isFullWidth variation="primary" onClick={() => setGlobalError(false)}>Retry</Button>
      </>
    )
  }


  if (loading || !businessName) {
    return <View className="center"><Loader /></View>
  }
  if (showWelcome) {
    return (
      <>
        <View className="center">
          <h2>Welcome to Mesh</h2>
        </View>
        <p>You've been invited to join the team at <b>{businessName}</b>.</p>
        <p>Before you start, we need a few details.</p>
        <Button isFullWidth variation="primary" onClick={() => setShowWelcome(false)}>Continue</Button>
      </>
    )
  }
  return (
    <>
      <h2>Personal Information</h2>
      <View>
        <TextField
          inputMode="text"
          placeholder="First name"
          label="First name"
          labelHidden
          errorMessage={errors.firstName}
          hasError={errors.firstName}
          name="firstName"
          onChange={(e) => setFirstName(e.currentTarget.value)}
          value={firstName}
          />
        <TextField
          inputMode="text"
          placeholder="Last name"
          label="Last name"
          errorMessage={errors.lastName}
          hasError={errors.lastName}
          labelHidden
          name="lastName"
          onChange={(e) => setLastName(e.currentTarget.value)}
          value={lastName}
          />
      </View>
      <h2>Security</h2>
      <View>
        <TextField
          inputMode="text"
          type="password"
          placeholder="Password"
          label="Password"
          errorMessage={errors.password}
          hasError={errors.password}
          labelHidden
          name="password"
          onChange={(e) => setPassword(e.currentTarget.value)}
          value={password}
          />
        <TextField
          inputMode="text"
          type="password"
          placeholder="Confirm Password"
          label="Confirm Password"
          errorMessage={errors.confirmPassword}
          hasError={errors.confirmPassword}
          labelHidden
          name="confirmPassword"
          onChange={(e) => setConfirmPassword(e.currentTarget.value)}
          value={confirmPassword}
          />
      </View>
      <CheckboxField className="terms-checkbox"
        name="terms"
        checked={terms}
        errorMessage={errors.terms}
        hasError={errors.terms}
        onChange={(e) => setTerms(e.target.checked)}
        label={<span>I agree to the Mesh <a href="https://www.mesh.id/terms-of-service" target="_blank">Terms & Conditions</a></span>}
      />
      <Button isFullWidth variation="primary" onClick={submitForm}>Save details</Button>
    </>
  )

}


const Signup = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const api = useContext(UserContext)

  const [invite, setInvite] = useState({})
  const [globalError, setGlobalError] = useState(false)
  const [globalErrorMessage, setGlobalErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  
  useEffect(() => {
    const fetchInvite = async () => {
      try {
        const { inviteId } = queryString.parse(location.search);
        const results = await api.validateInvite({ inviteId })
        if (results.inviteStatus !== 'VALID') {
          throw new Error('Invalid inviteId')
        }
        setInvite(results)
      } catch (e) {
        console.error(e)
        setGlobalError(true)
        return
      }
    }
    fetchInvite();
  }, [location, api])

  const signupUser = async ({ firstName, lastName, password }) => {
    setLoading(true)
    const { emailAddress, customerId, userId, inviteId } = invite
    const params = {
      username: emailAddress,
      password,
      attributes: {
        email: emailAddress,
        'custom:customer_id': customerId,
        'custom:invite_id': inviteId,
        'custom:user_id': userId,
        'given_name': firstName,
        'family_name': lastName,
      },
    }
    // TODO: How to handle errors

    try {
      const signup = await Auth.signUp(params)
      console.log('signup', signup)
      const signin = await Auth.signIn(emailAddress, password)  
      console.log('signin', signin)
    } catch (e) {
      if (ERROR_MESSAGES[e.code]) {
        setGlobalErrorMessage(ERROR_MESSAGES[e.code])
      }
      else {
        setGlobalErrorMessage(e.message)
      }
      setLoading(false)
      console.error(e)
      console.error(e.message)
      setGlobalError(true)
      return
    }

    // Then navigate to /home
    navigate('/')
  }

  return (
    <View className="signup">
      <View className="signup-container">
        <View textAlign="center" padding={24}>
          <img src={logo} />
        </View>
        <View className="signup-info-box">
          <View className="signup-form">
            <SignupForm
              businessName={invite.customerName}
              signupUser={signupUser}
              loading={loading}
              globalError={globalError}
              globalErrorMessage={globalErrorMessage}
              setGlobalError={setGlobalError} />
          </View>
        </View>
        
      </View>
    </View>
  );
}
export default Signup
