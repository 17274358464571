import { useState, useEffect, createContext } from "react"

// import exports from '../../src/aws-exports'
import { useAuthenticator } from '@aws-amplify/ui-react'

import {
  getWebhookPortal as getWebhookPortalQuery,
} from '../../src/graphql/queries'
import { API, graphqlOperation } from 'aws-amplify'

const WebhookPortalContext = createContext()
export default WebhookPortalContext

const ContextState = () => {
  const { user } = useAuthenticator((context) => [context.user])
  
  const [webhookPortal, setWebhookPortal] = useState(null)
  const [loaded, setLoaded] = useState(false)
  
  const webhookPortalCall = async () => {
    if (user) {
      const result = await API.graphql({
        ...graphqlOperation(getWebhookPortalQuery),
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })
      if (result.data.getWebhookPortal) {
        setWebhookPortal(result.data.getWebhookPortal.url)
      } else {
        setWebhookPortal(null)
      }
    }
  }

  useEffect(() => {
    webhookPortalCall()
  }, [user])
  useEffect(() => {
    if (webhookPortal) {
      setLoaded(true)
    }
  }, [webhookPortal])

  return {
    webhookPortal,
    loaded,
  }
}

const WebhookPortalContextProvider = (props) => {
  const state = ContextState()

  return (
    <WebhookPortalContext.Provider value={state}>
      {props.children}
    </WebhookPortalContext.Provider>
  ) 
}

export { WebhookPortalContext, WebhookPortalContextProvider }
